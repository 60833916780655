import { Controller } from "stimulus"
import MicroModal from 'micromodal';

var Trix = require("trix")
require("@rails/actiontext")

export default class extends Controller {
  static targets = [
    "policySectionBody",
    "definitionsField",
    "policyHeader",
    "policyHeaderToggle",
    "policyDefinitions",
    "policyDefinitionsToggle",
    "questionEmbedSelect"
  ]

  connect() {
    this.policyHeaderDoToggle()
    this.policyDefinitionsDoToggle()

    addEventListener('trix-initialize', (event) => {
      this.addTrixEmbedQuestionButton(event.target)
      this.addTrixEmbedInfoButton(event.target)
    })

    this.policySectionBodyTargets.forEach(element => {
      this.addTrixEmbedQuestionButton(element.querySelector('trix-editor'))
      this.addTrixEmbedInfoButton(element.querySelector('trix-editor'))
    })
  }

  // The policy has to be saved in order to search. Duh
  // Besides, this should all be done in js...
  // Disabling for now
  findDefinitions(event) {
    Rails.ajax({
      type: "post",
      url: this.data.get('definitions-url'),
      success: function(data) {
        let selectInputChoice = window.choicesInputs['policy[definitions][]']

        selectInputChoice.setChoiceByValue(data.definitions)
      }
    })

  }

  policyHeaderDoToggle() {
    if(this.policyHeaderToggleTarget.checked) {
      this.policyHeaderTarget.classList.remove('hidden')
    } else {
      this.policyHeaderTarget.classList.add('hidden')
    }
  }

  policyDefinitionsDoToggle() {
    if(this.policyDefinitionsToggleTarget.checked) {
      this.policyDefinitionsTarget.classList.remove('hidden')
    } else {
      this.policyDefinitionsTarget.classList.add('hidden')
    }
  }

  addTrixEmbedQuestionButton(trixElement) {
    // Is this a policy section?
    if(trixElement.closest('[data-policy-target="policySectionBody"]') !== null) {
      var buttonHTML = "<button type='button' class='trix-button' data-action='policy#openQuestionEmbedDialogue' style='font-size: 1.2em;'>Q</button>"

      // Does it already have a button?
      if(trixElement.toolbarElement.querySelector("[data-action='policy#openQuestionEmbedDialogue']") === null) {
        trixElement.toolbarElement.querySelector('[data-trix-button-group="text-tools"]')
        .insertAdjacentHTML("beforeend", buttonHTML)
      }
    }
  }

  addTrixEmbedInfoButton(trixElement) {
    // Is this a policy section?
    if(trixElement.closest('[data-policy-target="policySectionBody"]') !== null) {
      var buttonHTML = "<button type='button' class='trix-button' data-action='policy#openInfoEmbedDialogue' style='font-size: 1.2em;'>I</button>"

      // Does it already have a button?
      if(trixElement.toolbarElement.querySelector("[data-action='policy#openInfoEmbedDialogue']") === null) {
        trixElement.toolbarElement.querySelector('[data-trix-button-group="text-tools"]')
        .insertAdjacentHTML("beforeend", buttonHTML)
      }
    }
  }

  openQuestionEmbedDialogue(target) {
    MicroModal.show('question-embed-form', {
      onClose: (modal, target) => {
        // Pass the selected question to the inject method and reset the select
        var textArea = target.closest(".trix-editor-wrapper").querySelector('trix-editor')
        var questionId = modal.querySelector('[name=questionEmbedSelect]').value
        var questionName = modal.querySelector('option[value="'+questionId+'"]').text

        var attachment = new Trix.Attachment({
          content: "<span data-question-replace='"+ questionId +"'>" +
                     "<strong>[Q: "+ questionName +"]</strong>" +
                   "</span>"
        })

        textArea.editor.insertAttachment(attachment)
      }
    });
  }

  openInfoEmbedDialogue(target) {
    MicroModal.show('info-embed-form', {
      onClose: (modal, target) => {
        // Pass the selected question to the inject method and reset the select
        var textArea = target.closest(".trix-editor-wrapper").querySelector('trix-editor')
        var infoSelect = modal.querySelector('[name=infoEmbedSelect]')

        var infoLabel =
          `${infoSelect.options[infoSelect.selectedIndex].closest('optgroup').label}—${infoSelect.options[infoSelect.selectedIndex].text}`

        var attachment = new Trix.Attachment({
          content: "<span data-info-replace='"+ infoSelect.value +"'>" +
                     "<strong>[I: "+ infoLabel +"]</strong>" +
                   "</span>"
        })

        textArea.editor.insertAttachment(attachment)
      }
    });
  }

}
