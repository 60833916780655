// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
import Rails from "@rails/ujs";
window.Rails = Rails;

require("turbolinks").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
require("helpers/trix")
require("channels")

// Choices.js
require("choices.js")
import 'choices.js/public/assets/styles/choices.min.css';

// Polyfill modern JS features, like promises, async functions, etc.
import "core-js/stable";
import "regenerator-runtime/runtime";

// Images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Icon Font
import '@fortawesome/fontawesome-free/css/all.css';

// Stylesheets
import "stylesheets/application";

// Stimulus JS
import "controllers";
