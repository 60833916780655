import { Controller } from "stimulus"
import IMask from "imask";
import Choices from 'choices.js';
import Tagify from '@yaireo/tagify';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

if(window.choicesInputs == undefined) {
  window.choicesInputs = {}
}

if(window.tagifyInputs == undefined) {
  window.tagifyInputs = {}
}

export default class extends Controller {
  static targets = [
    'birthdayInput',
    'phoneInput',
    'trixInput',
    'passwordInput',
    'tagifyInput',
    'tooltip'
  ]

  connect() {
    // if(this.hasTrixInputTarget) {
    //   this.addTrixColorPicker()
    // }

    if(this.hasBirthdayInputTarget) {
      this.initBirthdayField(this.birthdayInputTarget)
    }

    if(this.hasPhoneInputTarget) {
      this.phoneInputTargets.forEach(element => {
        IMask(element, {
          mask: '+{1} (000) 000-0000 [x00000]'
        });
      })
    }

    if(this.hasTagifyInputTarget) {

      this.tagifyInputTargets.forEach(element => {
        if(!window.tagifyInputs[element.id]) {

          // This doesn't accomidate for all cases, just the norm
          window.tagifyInputs[element.id] = new Tagify(element, {
            whitelist: JSON.parse(element.dataset.collection),
            enforceWhitelist: element.dataset.allowCustom == undefined ? true : false,
            dropdown: {
              maxItems: 100,
              classname: "tags-look",
              enabled: 0,
              closeOnSelect: false
            }
          })
        }
      })
    }

    document.querySelectorAll('select.choices').forEach(choicesElement => {
      let options = {}

      if(choicesElement.dataset.options !== undefined) {
        options = JSON.parse(choicesElement.dataset.options)
      }

      if(!choicesElement.classList.contains('choices__input')) {
        if(!window.choicesInputs[choicesElement.id]) {
          window.choicesInputs[choicesElement.id] = new Choices(choicesElement, options)
        }
      }
    })


    if(this.hasTooltipTarget) {

      this.tooltipTargets.forEach(element => {

        // Add the tooltip indicator
        let tipHTML = `
          <span class="tooltip" style="font-size: 90%">
            <i class="far fa-question-circle"></i>
          </span>
        `

        element.querySelector(':scope > label').insertAdjacentHTML("beforeend", tipHTML);

        tippy(element.querySelector('.tooltip'), {
          content: element.dataset.tooltip,
          theme: 'light-border',
          allowHTML: true,
          placement: 'bottom',
          interactive: true,
          maxWidth: 400,
        });

      });
    }
  }

  // This is our manual submit call
  submit(event) {
    if(event.isInvalid) {
      event.preventDefault();
      event.stopPropagation();

      return false
    } else {
      if(this.element.dataset.remote) {
        return true
      }

      this.element.submit()
    }
  }

  // This event exists for us to validate the form and stop propagation before we get here
  // Because if you event.preventDefault() in the submit event, no more events will fire
  validate(event) {
    let validSubmit = true;

    if(this.hasTrixInputTarget) {
      validSubmit = this.validateTrixInputs();
    }

    // Validate drag_n_drop inputs for file presence
    this.element.querySelectorAll('.input.drag_n_drop.required').forEach(dragDrop => {
      let fileInput = dragDrop.querySelector('.dropzone > input[type=hidden]')

      if(fileInput == null || fileInput.value == '') {
        if(!dragDrop.classList.contains('has-error')) {
          let label = dragDrop.querySelector('label').textContent.replace(/\*\s*/, '')

          dragDrop.classList.add('has-error')
          dragDrop.append(
            document.createElement('span').appendChild(
              document.createTextNode(`${label} cannot be blank`)
            )
          );
        }

        validSubmit = false;
      }
    })

    if(!validSubmit) {
      event.isInvalid = true
      return false
    }
  }

  validateTrixInputs() {
    let valid = true

    this.trixInputTargets.forEach(trixWrapper => {
      let errorNode = document.createElement('span')
      errorNode.classList.add('error-message')

      // Is element visible?
      if(trixWrapper.offsetParent === null) {
        return
      }

      if(trixWrapper.querySelector('input').hasAttribute('required')) {
        if(trixWrapper.querySelector('input').value == '') {
          if(!trixWrapper.classList.contains('has-error')) {
            trixWrapper.classList.add('has-error')

            errorNode.appendChild(document.createTextNode("Value cannot be blank"))

            trixWrapper.append(errorNode);
          }

          valid = false
        } else {
          trixWrapper.classList.remove('has-error')

          if(trixWrapper.querySelector('.error-message') !== null) {
            trixWrapper.querySelector('.error-message').remove();
          }
        }
      }
    })

    return valid
  }

  initBirthdayField(field) {
    let birthdate = IMask(field, {
      mask: Date,
      pattern: '`d/`m',
      blocks: {
        d: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
          maxLength: 2,
        },
        m: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
        }
      },
      format: this.formatDate,
      parse: this.parseDate,
      autofix: true,
      lazy: false,
      overwrite: true
    });

  }

  formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;

    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;

    return [day, month].join('/');
  }

  parseDate(dateString) {
    let monthDay = dateString.split('/');
    return new Date(1970, monthDay[1] - 1, monthDay[0]);
  }


  // Password field toggle
  togglePasswordField(event) {
    event.preventDefault()
    event.stopPropagation()

    var passwordField = this.passwordInputTarget,
    textToggle = document.getElementById('password-show');

    textToggle.classList.toggle('active');

    if(textToggle.classList.contains('active')) {
      passwordField.type = 'text'
      textToggle.textContent = 'Hide';
    } else {
      passwordField.type = 'password'
      textToggle.textContent = 'Show';
    }
  }

  toggleOtherOption(event) {
    let parentInput = event.target.closest('.input'),
    customInput = parentInput.querySelector(`input[type=text][name='${event.target.name}']`)

    if(event.target.value == 'Other' || event.target.value == 'Custom') {
      customInput = document.createElement('input')
      customInput.type = 'text'
      customInput.name = event.target.name

      parentInput.appendChild(customInput)
    } else if(customInput) {
      customInput.remove()
    }
  }

}
