import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["trigger", "toggleable"]

  trigger() {
    event.preventDefault()
    event.stopPropagation()

    const target = event.currentTarget

    if(target.getAttribute('href').startsWith('#!')) {
      if(target.hasAttribute('data-hide-others')) {
        this.toggle(target, true)
      } else {
        this.toggle(target, false)
      }
    }
  }

  toggle(triggerTarget, hideAllOthers) {
    const idSelector = triggerTarget.getAttribute('href').replace('#!', '')
    const toggleTarget = this.toggleableTargets.find(toggleable => {
      return toggleable.getAttribute('id') == idSelector
    })

    if(hideAllOthers) {
      this.triggerTargets.filter(trigger => {
        return trigger != triggerTarget
      }).forEach(trigger => trigger.classList.remove('toggled'))

      this.toggleableTargets.filter(toggleable => {
        return toggleable != toggleTarget
      }).forEach(toggleable => toggleable.classList.add('hidden'))
    }

    if(toggleTarget !== undefined) {
      triggerTarget.classList.toggle('toggled')
      toggleTarget.classList.toggle('hidden')
    }
  }

}
