import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'deviceStatusInput',
    'disposalFields',
    'sanitationFields'
  ]

  connect() {
    this.disposalFieldsTarget.classList.add('hidden')
    this.sanitationFieldsTarget.classList.add('hidden')

    this.setDeviceStatusFields()
  }

  setDeviceStatusFields() {
    if(/disposed/i.test(this.deviceStatusInputTarget.value)) {
      this.disposalFieldsTarget.classList.remove('hidden')
      this.sanitationFieldsTarget.classList.add('hidden')

      this.sanitationFieldsTarget.querySelectorAll('input').forEach(input => input.value = '')
    } else if(/sanitized/i.test(this.deviceStatusInputTarget.value)) {
      this.sanitationFieldsTarget.classList.remove('hidden')
      this.disposalFieldsTarget.classList.add('hidden')

      this.disposalFieldsTarget.querySelectorAll('input').forEach(input => input.value = '')
    } else {
      this.sanitationFieldsTarget.classList.add('hidden')
      this.disposalFieldsTarget.classList.add('hidden')

      this.sanitationFieldsTarget.querySelectorAll('input').forEach(input => input.value = '')
      this.disposalFieldsTarget.querySelectorAll('input').forEach(input => input.value = '')
    }
  }
}
