import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'item',
  ]

  static values = {
    addPolicyPath: String,
    removePolicyPath: String,
    addDocumentPath: String,
    removeDocumentPath: String
  }

  connect() {
    // this.itemTargets
  }

  selectPolicy(event) {
    const item = event.target.closest('.policy-resource')

    if(item) {
      if(event.target.checked) {

        this.addPolicy(item, (data) => {
          if(data.error) {
            event.target.checked = false
            item.classList.add('error')

            setTimeout(() => {
              item.classList.remove('error')
            }, 3000)
          } else {

            item.classList.add('added')
            item.dataset.id = data.resource_id

            if(data.linkHtml) {
              let link = document.createElement('a')
              link.innerHTML = data.linkHtml.trim()

              item.querySelector('.label').append(link)
            }
          }
        })

      } else {

        if(window.confirm("Are you sure? This will remove this policy and all data from your document package.")) {
          this.removePolicy(item, (data) => {
            if(data.error) {

              event.target.checked = true
              item.classList.add('error')

              setTimeout(() => {
                item.classList.remove('error')
              }, 3000)

            } else {
              item.classList.remove('added')
              item.querySelector('.label a').remove()
              item.dataset.id = data.resource_id
            }
          })
        } else {
          event.target.checked = true
        }

      }
    }
  }

  selectDocument(event) {
    const item = event.target.closest('.document-resource')

    if(item) {
      if(event.target.checked) {

        this.addDocument(item, (data) => {
          if(data.error) {
            event.target.checked = false
            item.classList.add('error')

            setTimeout(() => {
              item.classList.remove('error')
            }, 3000)
          } else {

            item.classList.add('added')
            item.dataset.id = data.resource_id

            if(data.linkHtml) {
              let link = document.createElement('a')
              link.innerHTML = data.linkHtml.trim()

              item.querySelector('.label').append(link)
            }
          }
        })

      } else {

        if(window.confirm("Are you sure? This will remove this document from your document package.")) {
          this.removeDocument(item, (data) => {
            if(data.error) {

              event.target.checked = true
              item.classList.add('error')

              setTimeout(() => {
                item.classList.remove('error')
              }, 3000)

            } else {
              item.classList.remove('added')
              item.querySelector('.label a').remove()
              item.dataset.id = data.resource_id
            }
          })
        } else {
          event.target.checked = true
        }
      }
    }
  }

  addPolicy(item, callback) {
    this.doPost(item, this.addPolicyPathValue, callback)
  }

  removePolicy(item, callback) {
    this.doPost(item, this.removePolicyPathValue, callback)
  }

  addDocument(item, callback) {
    this.doPost(item, this.addDocumentPathValue, callback)
  }

  removeDocument(item, callback) {
    this.doPost(item, this.removeDocumentPathValue, callback)
  }

  doPost(item, url, callback) {
    item.classList.add('loading')

    Rails.ajax({
      type: "post",
      url: url,
      data: new URLSearchParams({
        resource_id: item.dataset.id
      }).toString(),
      success: callback,
      complete: () => {
        item.classList.remove('loading')
      }
    })
  }
}
