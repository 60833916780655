import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'actionSelect',
    'noneAction',
    'uploadAction',
    'policyAction',
    'documentAction',
    'action'
  ]

  availableActions = {
    'none': 'noneActionTarget',
    'upload': 'uploadActionTarget',
    'policy': 'policyActionTarget',
    'document': 'documentActionTarget',
  }

  connect() {
    this.selectAction();
  }

  selectAction() {
    Object.keys(this.availableActions).forEach(selectValue => {
      let actionTargetSelector = this.availableActions[selectValue],
      actionTarget = this[actionTargetSelector]

      if(this.actionSelectTarget.value == selectValue) {
        actionTarget.classList.add('active')
        actionTarget.querySelector('._destroy').value = 0
      } else {
        actionTarget.classList.remove('active')
        actionTarget.querySelector('._destroy').value = 1
      }
    })
  }

}
