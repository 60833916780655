import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'template',
    'addLink'
  ]

  static values = {
    addBlank: Boolean
  }

  connect() {
    if(this.addBlankValue) {
      this.doAddAssociation()
    }
  }

  add_association(event) {
    if(event.target.classList.contains('association-add')) {
      event.preventDefault()
      event.stopPropagation()
      this.doAddAssociation(event)
    }
  }

  doAddAssociation(event) {
    let new_group = document.createElement('div')
    new_group.innerHTML = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    event.target.parentNode.insertBefore(new_group, event.target)
    this.formsController.connect()
  }

  remove_association(event) {
    if(event.target.classList.contains('association-remove')) {
      event.preventDefault()
      event.stopPropagation()
      this.doRemoveAssociation()
    }
  }

  doRemoveAssociation() {

    // Whatever simple_fields_for is
    let group = event.target.closest('.nested-fields')

    // New records are simply removed from the page
    if (group.dataset.newRecord == "true") {
      group.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      group.querySelector("input[name*='_destroy']").value = 1
      group.style.display = 'none'
    }
  }

  get formsController() {
    return this.application.getControllerForElementAndIdentifier(this.element.closest('[data-controller=forms]'), 'forms')
  }

}
