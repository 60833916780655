import { Controller } from "stimulus"
import { delay } from "helpers";

export default class extends Controller {
  static targets = [
    'searchField',
    'results',
    'stepResults',
    'ruleResults',
    'questionResults',
    'policyResults',
    'documentResults',
    'uploadResults',
    'stepTemplate',
    'ruleTemplate',
    'questionTemplate',
    'policyTemplate',
    'documentTemplate',
    'uploadTemplate'
  ]

  static values = {
    url: String
  }

  connect() {
    this.searchTimer = 0
  }

  searchOnDelay(event) {
    this.resultsTarget.classList.add('loading')

    clearTimeout(this.searchTimer)

    this.searchTimer = setTimeout(
      () => this.search(event.target.value),
      500
    )
  }

  search(query) {

    this.clearResults()

    Rails.ajax({
      type: "post",
      url: this.urlValue,
      data: new URLSearchParams({
        query: query
      }).toString(),
      success: (data) => this.displayResults(data)
    })

  }

  clearResults() {
    this.resultsTarget.querySelectorAll('a').forEach(link => link.remove())
  }

  displayResults(data) {

    this.resultsTarget.classList.remove('loading')
    this.resultsTarget.classList.remove('no-results')

    if(data) {
      for(const search_type in data) {
        data[search_type].forEach((result) => {
          this.drawSearchResult(search_type, result)
        })
      }
    } else {
      this.resultsTarget.classList.add('no-results')
    }

  }

  drawSearchResult(type, result) {
    let resultTemplate = this[`${type}TemplateTarget`].content.cloneNode(true)

    resultTemplate.querySelector('[data-result-link]').href = result['url']

    for(const key in result.attributes) {
      let dataAttr = `[data-result-content=${key}]`
      let contentNode = resultTemplate.querySelector(dataAttr)

      contentNode.innerHTML = result.attributes[key]
      contentNode.removeAttribute(dataAttr)
    }

    this[`${type}ResultsTarget`].append(resultTemplate)
  }


}
