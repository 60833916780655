import { Controller } from "stimulus"
import MicroModal from 'micromodal';

export default class extends Controller {

  connect() {
    MicroModal.init({
      awaitCloseAnimation: true
    })
  }

}
