import { Controller } from "stimulus"

export default class extends Controller {
  // This should be implemented as a stimulus controller
  // It's so useful in design of apps, I keep jumping to it when thinking of interfaces
  // Should just bite the bullet and get it done.
  // Oh and no history tracking. That's annoying.
  // But still persist the tab on reload/redirect if it's in the hash tho
  static targets = ['tabHook', 'tab']

  connect() {
    if(this.hasTabTarget) {
      this.initTabs()
    }
  }

  initTabs() {
    this.setTab(window.location.hash)
  }

  switchTab(event) {
    event.preventDefault()

    this.setTab(event.target.hash)
  }

  setTab(search) {
    let foundTab = this.findTab(search)

    if(foundTab === undefined) {
      foundTab = this.tabTarget // First Tab
    }

    if(foundTab != this.currentTab()) {

      this.tabTargets.forEach(otherTab => {
        let tabHook = this.findTabHook(otherTab)

        if(foundTab == otherTab) {
          otherTab.classList.add('active')
          tabHook.classList.add('active')

          // Very obscure turbolinks hack?
          // https://github.com/turbolinks/turbolinks/issues/219#issuecomment-338171319
          history.replaceState(history.state, null, tabHook.hash);
        } else {
          otherTab.classList.remove('active')
          tabHook.classList.remove('active')
        }
      })
    }
  }

  findTab(hashOrTab) {
    if(typeof hashOrTab == 'string') {
      // hash
      let hash = hashOrTab.replace(/^#/, '')

      return this.tabTargets.find(tab => tab.id == hash)
    } else {
      // tab
      return hashOrTab
    }
  }

  findTabHook(tab) {
    return this.tabHookTargets.find(tabHook => tabHook.hash == "#" + tab.id)
  }

  currentTab() {
    this.tabTargets.find(tab => {
      tab.classList.contains('active')
    })
  }
}
