import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "input" ]

  initialize() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  createUrl({ params }) {

    Rails.ajax({
      type: "post",
      url: params.path,
      data: new URLSearchParams({
        user_id: params.userId,
        redirect: params.redirect
      }).toString(),
      success: (data) => this.writeUrl(data.url)
    })

  }

  writeUrl(url) {
    this.inputTarget.setAttribute('value', url)
    this.inputTarget.readOnly = true

    this.inputTarget.select()
    document.execCommand("copy")

    this.element.classList.add('__copied')

    this.inputTarget.setAttribute('value', '')
    this.inputTarget.readOnly = false

    setTimeout(() => {
      this.element.classList.remove('__copied')
    }, 2000)
  }
}
