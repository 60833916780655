import { Controller } from "stimulus"

export default class extends Controller {
  // Couldn't get this working, scrapped
  // connect() {
  //   window.addEventListener("beforeunload", (event) => {
  //     // Check upload form. If file is persisted, do nothing
  //     // If new file, alert
  //     debugger

  //     return true;
  //   })
  // }

}
