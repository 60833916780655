
var Trix = require("trix")
Trix.config.textAttributes.red = {
  style: { color: "red" },
  parser: element => element.style.color == "red",
  inheritable: true
}

Trix.config.textAttributes.blue = {
  style: { color: "blue" },
  parser: element => element.style.color == "blue",
  inheritable: true
}

Trix.config.textAttributes.orange = {
  style: { color: "orange" },
  parser: element => element.style.color == "orange",
  inheritable: true
}

 Trix.config.textAttributes.green = {
  style: { color: "green" },
  parser: element => element.style.color == "green",
  inheritable: true
}

function addColorPickerToTrixEditor(editor) {
  let buttonHTML = `
    <div class="color-picker">
      <button type="button" class="color-picker-toggle">🎨</button>

      <div class="menu">
        <button type="button" data-trix-attribute="red" class="colors__item colors__item--red">Red</button>
        <button type="button" data-trix-attribute="blue" class="colors__item colors__item--blue">Blue</button>
        <button type="button" data-trix-attribute="orange" class="colors__item colors__item--orange">Orange</button>
        <button type="button" data-trix-attribute="green" class="colors__item colors__item--green">Green</button>
      </div>
    </div>
  `

  if(editor.toolbarElement.querySelector(".color-picker") === null) {
    editor.toolbarElement
    .querySelector('[data-trix-button-group="text-tools"]')
    .insertAdjacentHTML("beforeend", buttonHTML);

    let colorPicker = editor.toolbarElement.querySelector(".color-picker"),
    colorPickerToggle = colorPicker.querySelector('.color-picker-toggle'),
    colorPickerItems = colorPicker.querySelectorAll(".colors__item")

    colorPickerToggle.addEventListener('click', (e) => {
      colorPicker.classList.toggle('active')
    })

    colorPickerItems.forEach((button) => {
      button.addEventListener('click', () => {
        colorPickerItems.forEach((otherButton) => {
          if(otherButton == button) {
            // This is the current button, default behavior
          } else {
            otherButton.classList.remove('trix-active')
            otherButton.removeAttribute('data-trix-active')
          }
        })

        colorPicker.classList.remove('active')
      })
    })
  }
}

addEventListener('trix-initialize', (event) => {
  if(document.documentElement.classList.contains('admin')) {
    addColorPickerToTrixEditor(event.target)
  }

  if(document.querySelector('.policy-review')) {
    addColorPickerToTrixEditor(event.target)
  }
})
