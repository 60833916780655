import { Controller } from "stimulus"

var Trix = require("trix")
require("@rails/actiontext")

export default class extends Controller {
  static targets = [
    'sectionForm',
    'preview'
  ]

  windowOptions = {
    toolbar: 'no',
    location: 'no',
    status: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'yes',
    width: 800,
    height: 600
  }

  windowOptionsString() {
    return Object.keys(this.windowOptions).map(key => `${key}=${this.windowOptions[key]}`).join(',')
  }

  connect() {
    this.previewWindow = null

    document.body.addEventListener("ajax:success", this.handleFormSuccess.bind(this))
  }

  disconnect() {
    if(this.previewWindow) {
      this.previewWindow.close()
    }
  }

  showPreview(event) {
    event.preventDefault()
    event.stopPropagation()

    this.openPreviewWindow(event.currentTarget.href)
  }

  openPreviewWindow(path) {
    this.previewWindow = window.open(
      path,
      'previewWindow',
      this.windowOptionsString()
    )
  }

  reloadPreviewWindows() {
    try {
      if(this.previewWindow) {
        this.previewWindow.location.reload()
      }

      if(this.hasPreviewTarget) {
        let previewFrameDoc = this.previewTarget.contentDocument || this.previewTarget.contentWindow.document
        previewFrameDoc.removeChild(previewFrameDoc.documentElement)

        this.previewTarget.contentWindow.location.reload()
      }
    } catch(e) {
      // Do nothing
    }
  }

  handleFormSuccess(event) {
    const [data, status, xhr] = event.detail;

    // Empty request, move along
    if(!data.success && data.errors.length == 0) {
      return;
    }

    this.reloadPreviewWindows()

    let thisForm = this.sectionFormTargets.find((sectionForm) => sectionForm.dataset.id == data.id)

    if(data.success) {
      thisForm.classList.add('__background-color-success')

      setTimeout(() => { thisForm.classList.remove('__background-color-success') }, 1000)
    } else {
      thisForm.classList.add('__background-color-error')

      console.log(data.errors)
    }
  }
}
